import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Main/index.tsx";
import Box from '@material-ui/core/Box';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Box padding={3} mdxType="Box">
      <p>{`This Privacy Policy sets out TaleFin Australia Pty Ltd's commitment to protecting the privacy of your personal information that we collect through banks.talefin.com, (Website), other addresses or channels, or directly from you.`}</p>
      <p>{`Please read the Privacy Policy carefully. You can contact us if you have any questions before you provide us with any personal information. You can contact us at `}<a parentName="p" {...{
          "href": "mailto:helpdesk@talefin.com"
        }}>{`helpdesk@talefin.com`}</a>{`, including to unsubscribe.`}</p>
      <p>{`If you provide us with certain information by which you can be identified, offline or when using this website, it will be dealt with in accordance with this Privacy Policy and the Australian Privacy Principles.`}</p>
      <p>{`If you provide personal information to us, this indicates that you have had sufficient opportunity to access the Privacy Policy and contact us, and that you have read and accepted the Privacy Policy. If you do not agree to the Privacy Policy, please do not provide us with personal information.`}</p>
      <p>{`If you do not wish to provide personal information to us, then you do not have to do so, however it may affect your use of this Website or any products and services offered on it.`}</p>
      <p>{`This Privacy Policy applies to all users of our website. The User Terms & Conditions may contain additional privacy terms that apply to users of our services. The Institution Terms & Conditions may contain additional privacy terms that apply to institutions we provide services to. These additional terms are available on our Website.`}</p>
      <p>{`This Privacy Policy forms part of the Website Terms of Use, which can be accessed on the Website.`}</p>
    </Box>
    <Box padding={3} mdxType="Box">
      <h3 {...{
        "id": "1-what-personal-information-do-we-collect-from-you",
        "style": {
          "position": "relative"
        }
      }}>{`1. What personal information do we collect from you?`}<a parentName="h3" {...{
          "href": "#1-what-personal-information-do-we-collect-from-you",
          "aria-label": "1 what personal information do we collect from you permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p>{`General information requested: We may collect personal information from you in the course of our business, including if you input personal information on the Website or otherwise provide personal information, requested by us. This information requested is set out in our requests. It may include your name, address, phone number, email address, and other personal and/or demographic information.`}</p>
      <p>{`Information about your use of our Website: As with most online businesses, we log information about your access and use of our Website, including (i) your transactions conducted on Website, including through the use of Internet cookies, (ii) the IP addresses from which you came to the Website, and (iii) your communications with our Website. We may use cookies and other tracking information from your computer, which enables us to tell when you use the Website and to help customise your Website experience.`}</p>
      <p>{`Additional: If we offer additional benefits to you, we may ask you to provide us with your personal information for these purposes. Providing this information is optional to you. If you do not provide this information to us, we may not be able to contact you or give you access to the additional benefits. .`}</p>
      <p>{`Institution : If you request us to access bank/financial information on your behalf, you will be asked to provide us with the name of your bank/financial institution, and your username and account log in details, so that the bank/financial institution can identify you to access your records and your account log in details.`}</p>
      <p>{`Payment: If you pay for a service then you need to provide your payment details, for example credit card, debit card or PayPal.`}</p>
      <p>{`Communication: If you choose to have your bank/financial statement data sent to you, you will need to provide us with an email address.`}</p>
      <p>{`Opinion: We may conduct surveys or market research to seek your opinion and feedback.`}</p>
      <p>{`We will not ask you to provide personal information that may disclose your political, religious or philosophical beliefs or affiliations, health and sexuality, racial or ethnic origin, or criminal convictions.`}</p>
    </Box>
    <Box padding={3} mdxType="Box">
      <h3 {...{
        "id": "2-how-do-we-use-the-personal-information-that-we-collect",
        "style": {
          "position": "relative"
        }
      }}>{`2. How do we use the personal information that we collect?`}<a parentName="h3" {...{
          "href": "#2-how-do-we-use-the-personal-information-that-we-collect",
          "aria-label": "2 how do we use the personal information that we collect permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p>{`General: We use certain personal information for purposes including internal record keeping; for business development and marketing purposes; identifying and understanding user needs; to contact you including for market research purposes; for marketing (including direct marketing) communication; and to customise, improve and ensure proper functioning of our Website.`}</p>
      <p>{`Additional: If we offer additional benefits to you, and you choose to provide additional personal information for these purposes, we may use your personal information to send you promotional information about third parties that we think you may find interesting.`}</p>
      <p>{`Institution: If you provide us with bank/financial information, we use this to access the bank/financial information that you request us to access on your behalf, from the bank/financial institution that you nominate, for the lender/financial institution that you nominate.`}</p>
      <p>{`Payment: If you provide us with personal information for payment details, then we will use the personal information for your payment.`}</p>
    </Box>
    <Box padding={3} mdxType="Box">
      <h3 {...{
        "id": "3-how-do-we-disclose-your-personal-information",
        "style": {
          "position": "relative"
        }
      }}>{`3. How do we disclose your personal information?`}<a parentName="h3" {...{
          "href": "#3-how-do-we-disclose-your-personal-information",
          "aria-label": "3 how do we disclose your personal information permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p>{`General: We customarily do not disclose, or permit the disclosure of your personal information:`}</p>
      <p>{`(a) in a way that could be used to identify an individual member in any circumstances; or`}</p>
      <p>{`(b) to unrelated third parties, including to third party recipients overseas`}</p>
      <p>{`except as described in this Privacy Policy and our User Terms & Conditions, unless we have your permission, or such disclosure is incidental to your use of the Website, or where we are required by law or regulatory authority to do so.`}</p>
      <p>{`You consent to us disclosing your personal information for any of the above listed exceptions. We may share your personal information with third party service providers who assist us in providing information, products or services to you. We would only do this if the third party has agreed to comply with the standards in our Privacy Policy.`}</p>
      <p>{`If there is a change of control of our business or a sale or transfer of business assets, we reserve the right to transfer to the extent permissible at law our user databases, together with any personal information and non-personal information contained in those databases. This information may be disclosed to a potential purchaser. We would seek to only disclose information in good faith and where we have sought to maintain confidentiality.`}</p>
      <p>{`Institution: We disclose the bank/financial information that you provide to us (including your username and account log in details) as required to the bank/financial institution to access the information that you request us to access on your behalf.`}</p>
      <p>{`Payment: We disclose the personal information that you provide to use for payments, as directed by you to process your payment.`}</p>
    </Box>
    <Box padding={3} mdxType="Box">
      <h3 {...{
        "id": "4-your-rights",
        "style": {
          "position": "relative"
        }
      }}>{`4. Your Rights:`}<a parentName="h3" {...{
          "href": "#4-your-rights",
          "aria-label": "4 your rights permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p>{`Consent: Providing us with your personal information is optional to you. You can choose not to subscribe or not to provide the personal information. When you provide us with your personal information, you consent to the terms outlined in this Privacy Policy.`}</p>
      <p>{`Restrict: You may choose to restrict the collection or use of your personal information. If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by contacting us at the email address listed at the start of the Privacy Policy.`}</p>
      <p>{`Access: You have the right to request details of personal information that we hold about you, in certain circumstances set out in the Privacy Act 1988 (Cth). If you would like a copy of the personal information which we hold about you, please contact us at the email address listed at the start of the Privacy Policy. An administrative fee may be payable for the provision of information.`}</p>
      <p>{`Correction: If you want to change your personal information, or you believe that any information we hold on you is inaccurate, out of date, incomplete, irrelevant or misleading, please contact us at the email address listed at the start of the Privacy Policy. We will respond to any request within a reasonable time. We will endeavour to promptly correct any information found to be incorrect, so that the information is accurate, up to date, complete, relevant and not misleading.
Complaints: If you believe that we have breached the Australian Privacy Principles and wish to make a complaint about that breach then please contact us at the email address listed at the start of the Privacy Policy, setting out details of the breach. We will promptly investigate your complaint and respond to you in writing within 4 weeks, setting out the outcome of our investigation, what steps we propose to take to remedy the breach and any other action we will take to deal with your complaint.`}</p>
      <p>{`Unsubscribe: To unsubscribe from our email database, or opt out of any communications, please contact us at the email address listed at the start of the Privacy Policy, with "Unsubscribe" in the subject line of the e-mail.`}</p>
    </Box>
    <Box padding={3} mdxType="Box">
      <h3 {...{
        "id": "5-amendments-to-this-privacy-policy",
        "style": {
          "position": "relative"
        }
      }}>{`5. Amendments to this Privacy Policy`}<a parentName="h3" {...{
          "href": "#5-amendments-to-this-privacy-policy",
          "aria-label": "5 amendments to this privacy policy permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p>{`This Privacy Policy may be amended, including with changes, additions and deletions, from time to time at our sole discretion. Your continued use of our Website and services following any amendments indicates that you accept the amendments. You should check the Privacy Policy regularly, prior to providing personal information, to ensure you are aware of any changes, and only proceed to provide personal information if you accept the new Privacy Policy.`}</p>
    </Box>
    <Box padding={3} mdxType="Box">
      <h3 {...{
        "id": "6-storage-and-security",
        "style": {
          "position": "relative"
        }
      }}>{`6. Storage and Security`}<a parentName="h3" {...{
          "href": "#6-storage-and-security",
          "aria-label": "6 storage and security permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p>{`General: We are committed to keeping your personal information secure. We take all reasonable steps to ensure that the personal information collected from you is accurate, up to date, complete and stored in a secure environment and accessed only by our authorised personnel. Our service is a read only service. We have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information and protect it from misuse, interference, loss and unauthorised access, modification and disclosure. Connections are encrypted using 256-bit encryption with 2048-bit keys. All server login access is monitored. Anomalous and potentially fraudulent activity is detected and logged, and access may be revoked temporarily or permanently.`}</p>
      <p>{`Transmission: Please note that no information transmitted over the Internet can be guaranteed to be secure. We cannot guarantee the security of any information that you transmit to us, or receive from us. The transmission and exchange of information is carried out at your own risk.`}</p>
      <p>{`Institution: We may store generated bank/financial statements on behalf of the lender/financial institution. We may also store anonymous account and transaction data, and other data obtained through your financial institution, in a way which is not personally identifiable, and is encrypted where possible. This data may be used to provide better services to you and other users of our website and service, or to generate anonymous aggregate data.`}</p>
    </Box>
    <Box padding={3} mdxType="Box">
      <h3 {...{
        "id": "7-links-to-third-party-websites",
        "style": {
          "position": "relative"
        }
      }}>{`7. Links to third party websites`}<a parentName="h3" {...{
          "href": "#7-links-to-third-party-websites",
          "aria-label": "7 links to third party websites permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p>{`Our Website may contain links to third party websites. We do not have any control over those websites. We are not responsible for or liable for the protection and privacy of any information which you provide whilst visiting such websites and such websites are not governed by this Privacy Policy.`}</p>
    </Box>
    <Box padding={3} mdxType="Box">Thank you for reading our Privacy Policy.</Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      